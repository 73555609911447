<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "Product",

  metaInfo: { title: "Product" },

  extends: View,

  mixins: [
    LoadSections([
      "product-description",
      "product-carousel",
      "deal-flow-heading",
      //"alternating-dealflow",
      "tool-carousel",
      //"dealflow-features",
      "dealflow-more-features",
      "token-x-heading",
      "alternating-token-x",
      "tokenx-more-features",
      "one-view-heading",
      "one-view-features",
      "first-vault-heading",
      "first-vault-features",
      "newsletter",
      "info-alt",
    ]),
  ],

  props: {
    id: {
      type: String,
      default: "products",
    },
  },
};
</script>
